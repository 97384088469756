export async function callout() {
    $('.callout[data-closable]').on('closed.zf.reveal', (e) => {
        let callout = e.currentTarget,
            closestSection = callout.closest('section');

        if (closestSection) {
            let contentBands = closestSection.querySelectorAll('.content-band');

            if (contentBands.length && contentBands.length === 1) {
                closestSection.remove();
            } else {
                let contentBandWrapper = callout.closest('.content-band--wrapper');

                contentBandWrapper.remove();
            }
        }
    });
}
