export async function initSearch() {
    const realtySearch = document.querySelector('[data-realty-search]'),
        crosslinks = document.querySelector('[data-crosslinks]');

    if (realtySearch) {
        const { initRealtySearch } = await import(/* webpackMode: "lazy" */ './inc/realtySearch.vue.async');

        initRealtySearch(realtySearch);
    } else {
        const searchResults = document.querySelector('.realty-search-results');

        if (searchResults) {
            const { initSearchResults } = await import(/* webpackMode: "lazy" */ './inc/searchResults.vue.async');

            initSearchResults(searchResults);
        } else {
            const singleRealty = document.querySelector('.single-realty');

            if (singleRealty) {
                const { initSingleRealty } = await import(/* webpackMode: "lazy" */ './inc/singleRealty.async');

                initSingleRealty(singleRealty);
            }
        }
    }

    if (crosslinks && CONFIG.misc.crosslinksEndpoint) {
        fetch(CONFIG.misc.crosslinksEndpoint, { method: 'GET' })
            .then((result) => {
                if (result.ok) {
                    return result.json();
                }

                throw new Error('Invalid response');
            })
            .then((result) => {
                if (result.valid && result.html) {
                    const html = new DOMParser().parseFromString(result.html, 'text/html'),
                        securedHTML = html.body.innerHTML.replaceAll(/<\/?script>?/gi);

                    crosslinks.insertAdjacentHTML('afterbegin', securedHTML);
                    crosslinks.closest('[data-crosslinks-container]').hidden = false;

                    requestAnimationFrame(() => {
                        const swipers = crosslinks.querySelectorAll('.swiper-container[data-enabled="true"]');

                        if (swipers.length) {
                            import(/* webpackMode: "lazy" */ '../layout/swiper/swiper.async.js').then(
                                ({ initSwiper }) => {
                                    initSwiper(swipers);
                                },
                            );
                        }
                    });
                }
            })
            .catch(() => {
                //
            });
    }
}
