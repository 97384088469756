/** @module form */

export async function realEstates() {
    const realtySource = document.querySelector('[data-real-estate-source]'),
        realtyTarget = document.querySelector('[data-real-estate-target]');

    if (realtySource && realtyTarget) {
        let { initRealEstates } = await import(/* webpackMode: "lazy" */ './inc/realEstates.async');

        initRealEstates(realtySource, realtyTarget);
    }
}
