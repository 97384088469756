import Cookies from 'js-cookie';
import { liveEvents } from './index';

export async function initFavorites() {
    const events = await liveEvents(),
        linkToFavorites = document.querySelector('[data-favorites-link]'),
        cookieName = 'favorites',
        isFavoriteStateClass = 'is-favorite',
        config = CONFIG.misc.favorites;

    let favorites = Cookies.getJSON(cookieName);

    if (!favorites || !Array.isArray(favorites)) {
        favorites = [];
    } else {
        favorites = favorites.filter((fav) => !isNaN(fav));
    }

    if (linkToFavorites) {
        linkToFavorites.hidden = favorites.length === 0;
    }

    favorites.forEach((id) => {
        const button = document.querySelector(`[data-favorite='${id}']`),
            buttonLabel = button && button.querySelector('.label');

        if (button) {
            button.classList.add(isFavoriteStateClass);
            button.title = button.dataset.removeTitle;

            if (buttonLabel) {
                buttonLabel.innerText = button.dataset.removeLabel;
            }
        }
    });

    events.addEventListener(
        '[data-favorite]',
        'click',
        (event, selector) => {
            const button = event.target.closest(selector),
                id = button.dataset.favorite,
                favoriteIndex = favorites.indexOf(id),
                buttonLabel = button.querySelector('.label');

            if (favoriteIndex === -1) {
                // add to favorites
                favorites = Array.from(
                    new Set([...favorites, id]), // filter duplicates
                );

                if (linkToFavorites) {
                    linkToFavorites.hidden = false;
                }

                button.classList.add(isFavoriteStateClass);
                button.title = button.dataset.removeTitle;

                if (buttonLabel) {
                    buttonLabel.innerText = button.dataset.removeLabel;
                }
            } else {
                // remove from favorites
                favorites.splice(favoriteIndex, 1);

                if (linkToFavorites) {
                    linkToFavorites.hidden = favorites.length === 0;
                }

                if (config.onFavoritesPage) {
                    // when on favorites page
                    const currentURL = new URL(location.href),
                        currentURLParams = currentURL.searchParams;

                    // hide the corresponding card...
                    button.closest('[data-result]').hidden = true;

                    let favoritesCards = document.querySelectorAll('[data-result]:not([hidden])');

                    currentURLParams.set('fav', favorites.join(','));
                    currentURL.search = currentURLParams.toString();

                    // update the URL for share-ability
                    history.replaceState({}, document.title, currentURL.toString());

                    // ... and inform the maps about, so that the markers get updated
                    document.querySelectorAll('[data-custom-map]').forEach((map) => {
                        map.dispatchEvent(new CustomEvent('filter.filtered', { bubbles: true, cancelable: false }));
                    });

                    if (favoritesCards.length === 0) {
                        document.querySelector('[data-favorites-results]').style.display = 'none';
                        document.querySelector('[data-no-favorites-warning]').hidden = false;
                    }
                } else {
                    // just update the button
                    button.classList.remove(isFavoriteStateClass);
                    button.title = button.dataset.addTitle;

                    if (buttonLabel) {
                        buttonLabel.innerText = button.dataset.addLabel;
                    }
                }
            }

            if (linkToFavorites) {
                // update the link to the favorites
                // although this page reads the favorites also from cookies, the url should be easily shareable
                // -> IDs get updated in the URL
                const linkToFavoritesURL = new URL(linkToFavorites.href),
                    linkToFavoritesURLParams = linkToFavoritesURL.searchParams;

                linkToFavoritesURLParams.set('fav', favorites.join(','));

                linkToFavoritesURL.search = linkToFavoritesURLParams.toString();

                linkToFavorites.href = linkToFavoritesURL.toString();
            }

            Cookies.set(cookieName, favorites, { expires: 365, path: '/', secure: true });
        },
        { passive: true },
    );
}
